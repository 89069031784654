@charset "utf-8";
// Core variables and mixins
@import "base/variables.scss";
@import "base/mixins.scss";

// Custom colors
$primary: #a3a1b7 !default;
$white: #000000 !default;
$black: #000 !default;

// Custom Images
$header-image: "../img/hero-02.jpg";
$contact-image: "../img/contact.png";

// Global CSS
@import "base/page.scss";

// Components
@import "components/buttons.scss";
@import "components/navbar.scss";

// Layout
@import "layout/masthead.scss";
@import "layout/services.scss";
@import "layout/portfolio.scss";
@import "layout/timeline.scss";
@import "layout/team.scss";
@import "layout/contact.scss";
@import "layout/footer.scss";

   /*! div style */
   .image-gallery {
    width: 100%;
    display: grid;
//    grid-template-columns: repeat(auto-fill,minmax(calc(min(100%, 200px)), 1fr));
    grid-template-columns: repeat(auto-fit, 250px);
    grid-auto-columns: max-content;
    justify-content: center;
    gap: 5px;
    padding: 0;
  }

  .box {
      flex-basis: 25%;
      width: 100%;
      padding: 8px;
      margin: 2px;
  }

  .img-gallery {
	width: 100%;
  height: 200px;
	object-fit: cover;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  }